















































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError',
      'filter', 'report_data', 'cities',
      'merchant_categories',
    ]),
    ...mapGetters(STORE_KEY, [
      'summary',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData',
    ]),
  },
  components: {
    TitleBar,
  },
})
export default class ReportByCards extends Vue {

  public showModal: any = false
  public filter!: any


  get titleStack() {
    return [
      'Отчет по активированным картам',
    ]
  }

  public getQueryParam(field: string) {
    return this.$route.query ? cloneDeep(this.$route.query[field]) : null
  }

  // ---------------------------------------------------------------------------
  public setFilter() {
    console.log(this.filter)
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.filter)
    this.showModal = false
  }

  // ---------------------------------------------------------------------------

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/loadData`)
    this.$store.dispatch(`${STORE_KEY}/loadCities`)
    this.$store.dispatch(`${STORE_KEY}/loadMerchantCategories`)
  }

  public created() {
    // console.log(this.$store._modules.root._children)
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
