import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'

interface ReportByChannelState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  report_data: any[];
  cities: any[];
  merchant_categories: any[];
  filter: any;
}

const $state: ReportByChannelState = {
  loading: false,
  loaded: false,
  fatalError: false,

  report_data: [],
  cities: [],
  merchant_categories: [],
  filter: {
    city_id: null,
    merchant_category_id: null,
    date_register_from: null,
    date_register_to: null,
    date_activate_from: null,
    date_activate_to: null,
  },
}

export const $actions: ActionTree<ReportByChannelState, RootState> = {
  loadData({ commit, getters, dispatch }, query) {
    console.log(123, query)
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      const params: any = {}
      if (query) {
        params.date_register_from = query.date_register_from
        params.date_register_to = query.date_register_to
        params.date_activate_from = query.date_activate_from
        params.date_activate_to = query.date_activate_to
        params.city_id = query.city_id
        params.merchant_category_id = query.merchant_category_id
      }

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/reports/by_cards', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  loadCities({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/cities`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_CITIES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },
  loadMerchantCategories({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/merchant_categories`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_MERCHANT_CATEGORIES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

}

const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})

export const $mutations: MutationTree<ReportByChannelState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_ITEMS_LIST(state, data) {
    state.report_data = data.data
  },
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  },
  SET_MERCHANT_CATEGORIES: (state, { merchant_categories }) => {
    state.merchant_categories = merchant_categories.sort(compare)
  },
}

export const $getters:
  GetterTree<ReportByChannelState, RootState> = {
  summary(state) {
    const sum = {
      total_outlets: 0,
      total_count: 0,
      total_activated: 0,
    }
    state.report_data.forEach((obj: any) => {
      sum.total_outlets += obj.outlets_count
      sum.total_count += obj.cards_count > -1 ? obj.cards_count : 0
      sum.total_activated += obj.cards_activated > -1 ? obj.cards_activated : 0
      obj.outlets.forEach((row: any) => {
        sum.total_count += row.cards_count > -1 ? row.cards_count : 0
        sum.total_activated += row.cards_activated > -1 ? row.cards_activated : 0
      })
    })
    return sum
  },
}

export const STORE_KEY = '$_report_by_channel'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
